import React from "react"
import PropTypes from "prop-types"
import {
  ScrollingProvider,
  useScrollSections,
  Section as ScrollSection,
} from "react-scroll-section"

const SectionToC = ({ className }) => {
  const sections = useScrollSections()

  return (
    <div className="lg:sticky lg:top-16 lg:pl-10 lg:py-8">
      <div className={`${className} flex flex-col`}>
        {sections.map(({ id, onClick, selected }, index) => (
          <button
            key={index}
            onClick={onClick}
            selected={selected}
            className={`inline-block w-full max-w-3xl my-3 px-3 border-l-4 border-transparent cursor-pointer ${
              selected && "border-gold rounded"
            } focus:outline-none`}
          >
            <h5 className="text-left w-full">{id}</h5>
          </button>
        ))}
      </div>
    </div>
  )
}

const SectionDescription = ({ sections }) => {
  return (
    <ScrollingProvider debounceDelay={50} offset={-100}>
      <div className="flex place-items-start">
        <div className="w-full max-w-3xl">
          {sections.map(({ subtitle, isPrimary, texts }, index) => (
            <ScrollSection id={subtitle}>
              <div key={index} className="w-full">
                <h5
                  className={`${
                    isPrimary ? "text-xl md:text-2xl" : "text-base md:text-lg"
                  } mt-6 mb-3 font-bold text-left w-full font-display`}
                >
                  <span>{subtitle}</span>
                </h5>
                {texts.map(({ text, isHighlighted }) => (
                  <p
                    key={text}
                    className={`${
                      isHighlighted
                        ? "border-l-4 border-gold pl-4 bg-silver-light py-4 pr-4"
                        : ""
                    } text-justify mb-2`}
                  >
                    {text}
                  </p>
                ))}
              </div>
            </ScrollSection>
          ))}
        </div>
        <SectionToC className="hidden lg:flex max-w-xl" sections={sections} />
      </div>
    </ScrollingProvider>
  )
}

SectionDescription.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      subtitle: PropTypes.string,
      texts: PropTypes.arrayOf(PropTypes.string),
    })
  ),
}

export default SectionDescription
