import React from "react"
import PropTypes from "prop-types"
import { Icon } from "../../display"

const ReasonItem = ({ title, image }) => {
  return (
    <div
      key={title}
      className="flex flex-col items-center w-48 px-4 pb-2 pt-6 m-3 bg-silver-light rounded-xl"
    >
      <div className="pb-4">
        <div className="rounded-full bg-jet w-24 h-24 p-2">
          <Icon name={image} className="text-gold w-full h-12 mx-auto my-4" />
        </div>
      </div>
      <h6 className="text-sm leading-relaxed font-display font-semibold py-3 text-center">
        {title}
      </h6>
    </div>
  )
}

const SectionReason = ({ reasons }) => {
  const aReasons1 = reasons.slice(0, Math.floor(reasons.length / 2))
  const aReasons2 = reasons.slice(
    Math.floor(reasons.length / 2),
    reasons.length
  )
  return (
    <div className="mb-6">
      <div className="mb-8 text-3xl md:text-4xl font-bold text-center font-display mx-auto w-max">
        <h3>Why Us?</h3>
        <div className="mt-2 w-1/2 bg-gold h-2 ml-auto mr-0 rounded" />
      </div>
      <div className="p-8 max-w-4xl mx-auto">
        <div className="flex flex-col md:flex-row flex-wrap items-center md:items-stretch h-full justify-center">
          {aReasons1.map(({ title, image }) => (
            <ReasonItem title={title} image={image} />
          ))}
        </div>
        <div className="flex flex-col md:flex-row flex-wrap items-center md:items-stretch h-full justify-center">
          {aReasons2.map(({ title, image }) => (
            <ReasonItem title={title} image={image} />
          ))}
        </div>
      </div>
    </div>
  )
}

SectionReason.propTypes = {
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    })
  ),
}

export default SectionReason
