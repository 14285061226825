import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Icon, PageTitle } from "../components/display"
import SectionReason from "../components/sections/services/SectionReason"
import SectionDescription from "../components/sections/services/SectionDescription"
import SectionGetAitified from "../components/sections/SectionGetAitified"

const ServicePage = ({ data, location }) => {
  const { seo, title, descriptionTexts, action, image, reasons, sections } =
    data.servicesYaml

  return (
    <Layout location={location} title={title}>
      <Seo title={seo.title} description={seo.description} />
      <section>
        <div className="container px-4 mx-auto">
          <PageTitle className="mt-16">{title}</PageTitle>
          {descriptionTexts && (
            <div className="flex flex-col-reverse lg:flex-row items-center mb-10">
              <div className="w-full text-justify">
                {descriptionTexts.map(({ text, isHighlighted }) => (
                  <p
                    key={text}
                    className={`${
                      isHighlighted
                        ? "border-l-4 border-gold pl-4 bg-silver-light py-4 pr-4"
                        : ""
                    } mb-2`}
                  >
                    {text}
                  </p>
                ))}
              </div>
              <div className="w-full">
                <Icon
                  name={image}
                  className="text-gold w-full h-full mx-auto my-4"
                />
              </div>
            </div>
          )}
          {reasons && <SectionReason reasons={reasons} />}
          {sections && <SectionDescription sections={sections} />}
          <SectionGetAitified action={action} description={action.call} />
        </div>
      </section>
    </Layout>
  )
}

export default ServicePage

export const pageQuery = graphql`
  query ServiceBySlug($id: String!) {
    servicesYaml(id: { eq: $id }) {
      seo {
        title
        description
      }
      title
      descriptionTexts {
        text
        isHighlighted
      }
      action {
        call
        href
        label
      }
      image
      reasons {
        image
        title
      }
      sections {
        subtitle
        isPrimary
        texts {
          text
          isHighlighted
        }
      }
    }
  }
`
